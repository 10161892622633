<perfect-scrollbar class="scrollable-area"
    [config]="{wheelPropagation: false}">
    <div class="content">
        <div class="logo-icon">
        </div>
        <div class="terms-of-use-wrapper app-border"
            [formGroup]="model.form">
            <terms-of-use-content></terms-of-use-content>
            <mat-checkbox class="agree-to-terms-check"
                formControlName="isApproved">Agree to terms</mat-checkbox>
            <div class="panel-buttons">
                <div class="wrapper-ripple"
                    nk-ripple>
                    <input type="button"
                        value="{{res.approved}}"
                        [disabled]="isDisabledApprove()"
                        (click)="approve()"
                        class="button primary-button" />
                </div>
            </div>
        </div>
    </div>
</perfect-scrollbar>