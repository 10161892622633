import {
    Component
} from '@angular/core';
import {
    LicenseRes
} from '@app/core';

@Component({
    selector: 'terms-of-use-content',
    templateUrl: 'termsOfUseContent.component.html',
    styleUrls: ['./termsOfUseContent.component.scss'],
    host: {
        class: 'terms-of-use-content'
    }
})

export class TermsOfUseContentComponent {

    constructor(
        public res: LicenseRes
    ) {
    }


}
