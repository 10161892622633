import {
    Component
} from '@angular/core';
import { LicenseRes } from '@app/core';

@Component({
    selector: 'no-telemetry',
    templateUrl: 'noTelemetry.component.html',
    styleUrls: ['./noTelemetry.component.scss'],
    host: {
        class: 'no-telemetry'
    }
})

export class NoTelemetryComponent  {

    constructor(
        public res: LicenseRes
    ) {
    }

    public buyLicense(): void {
        
    }

 }
