import {
    Component
} from '@angular/core';
import { LicenseRes } from '@app/core';

@Component({
    selector: 'no-analytics',
    templateUrl: 'noAnalytics.component.html',
    styleUrls: ['./noAnalytics.component.scss'],
    host: {
        class: 'no-analytics'
    }
})

export class NoAnalyticsComponent  {

    constructor(
        public res: LicenseRes
    ) {
    }

    public buyLicense(): void {
        
    }

 }
