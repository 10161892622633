<div class="title">
    Terms Of Use
</div>
<div class="paragraph">
    <span class="text">
        Welcome to Pointgrab’s cloud-based web-service for monitoring and operating the PointGrab
        CogniPoinTM
        sensing platform (the <span class="bold">“Service”</span>). The Service is owned and operated by
        PointGrab
        Ltd. (<span class="bold">“PointGrab“</span>) and
        these Terms of Use will govern the relationship between you and PointGrab with respect to your use
        of
        the
        Service. You are granted access to the Service by virtue of being affiliated with a person or entity
        who
        acquired the Service from PointGrab (the <span class="bold">“Customer”</span>). Accordingly, such
        access
        shall terminate when the
        relationship between PointGrab and the Customer will end, for whatever reason, or in case the
        Customer
        shall
        request that such access shall be terminated.
    </span>
</div>
<br>
<div class="paragraph">
    <span class="text">
        PLEASE READ CAREFULLY THE FOLLOWING TERMS AND CONDITIONS (THE <span class="bold">“TERMS”</span>),
        WHICH
        CONSTITUTE A BINDING AGREEMENT
        BETWEEN YOU AND POINTGRAB. BY ACCESSING THE SERVICE OR BY USING IT IN ANY MANNER, YOU SIGNIFY YOUR
        ACCEPTANCE OF THESE TERMS. IF YOU DO NOT AGREE TO THESE TERMS, YOU MAY NOT ACCESS OR USE THE
        SERVICE.
    </span>
</div>
<br>
<div class="paragraph">
    <span class="text">
        From time to time, PointGrab may change these Terms to meet technical, operational and legal
        changes.
        PointGrab will post a notice about changes made to the Terms on PointGrab’s website, and may send
        you a
        notice by email. By continuing to use the Service after the new changes have taken effect, you
        indicate
        your
        agreement to the amended Terms.
    </span>
</div>
<div class="paragraph">
    <h3>Grant of Right</h3>
    <span class="text">
        Subject to these Terms, PointGrab grants you a limited, non-exclusive, revocable, non-transferable,
        non-sublicensable right to use the Service solely for the Customer’s business purposes and not for
        further
        commercialization or resale. Except as explicitly provided in these Terms, you may not use the
        Service
        for
        any other purpose.
    </span>
</div>
<div class="paragraph">
    <h3>User Account</h3>
    <span class="text">
        To login in order to use the Service, you must provide your email address and password. PointGrab
        may
        also
        establish and require from time to time additional or different means of identification and
        authentication
        for logging in and accessing the Service. You will maintain your login details in absolute
        confidentiality.
        Make sure that you change your password at least once every six months. You will immediately notify
        to
        PointGrab of any potential or actual misuse, unauthorized use, of your account.
    </span>
    <span class="text">
        Notwithstanding any remedies that may be available to PointGrab under any applicable law, PointGrab
        may
        temporarily or
        permanently deny, limit, suspend, or terminate your user account, prohibit you from accessing the
        Service,
        and take
        technical and legal measures to keep you off the Service, if PointGrab determines in its sole
        discretion
        that you: (i)
        abused your rights to use the Service; (ii) breached the Terms; (iii) violated any applicable law,
        rule,
        or
        regulation;
        and/or (iv) performed any act or omission which is harmful or likely to be harmful to us, or any
        other
        third
        party,
        including other users or providers of the Service.
    </span>
</div>
<div class="paragraph">
    <h3>Acceptable Use of the Service</h3>
    <span class="text">
        When using the Service, you will abide by the applicable laws, rules and regulations, and by any
        usage
        guidelines
        PointGrab may convey to you from time to time. You may not access or use the Service for any illegal
        or
        abusive
        purposes, or to develop or create a similar or competitive product or service to the Service. You
        will
        be
        responsible
        for all acts and omissions associated with your access and use of the Service.
    </span>
</div>
<div class="paragraph">
    <h3>Privacy and Data Protection</h3>
    <span class="text">
        PointGrab provides the Service subject to PointGrab’s privacy policy, located at <a target="_blank"
            href="https://www.pointgrab.com/privacy-policy">
            https://www.pointgrab.com/privacy-policy</a>.
    </span>
</div>
<div class="paragraph">
    <h3>Intellectual Property</h3>
    <span class="text">
        All rights, title and interest in and to the Service, including any intellectual property rights,
        whether
        registered or not, and any goodwill associated therewith, are owned by PointGrab. These Terms do not
        grant
        you any rights to patents, copyrights, trademarks (whether registered or unregistered), trade names,
        trade
        secrets, domain names or any other rights, functions or licenses with respect to the Service, except
        for
        the
        access and use of the Service for the benefit of the Customer in compliance with these Terms. You
        shall
        not
        reverse engineer or attempt to find the underlying code of any element of the Service. You shall not
        circumvent any security or access control mechanisms of the Service. You shall not make the Service
        available to any third party.
    </span>
</div>
<div class="paragraph">
    <h3>Disclaimer of Warranty</h3>
    <span class="text">
        YOU ACKNOWLEDGE AND AGREE THAT THE SERVICE IS PROVIDED FOR USE “AS IS”, WITHOUT ANY WARRANTIES OF
        ACCURACY,
        RELIABILITY,
        LIKELY-RESULT, MERCHANTABILITY, QUALITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT,
        COMPATIBILITY,
        SECURITY OR
        IT BEING UP-TO-DATE. USE OF THE SERVICE IS AT YOUR SOLE AND EXCLUSIVE RISK. TO THE MAXIMUM EXTENT
        PERMITTED
        BY LAW,
        POINTGRAB DISCLAIM ANY AND ALL WARRANTIES, CONDITIONS, REPRESENTATIONS, AND GUARANTEES WITH RESPECT
        TO
        THE
        SERVICE, THE
        ACCURACY, RELIABILITY, ADEQUACY OR COMPLETENESS OF THE SERVICE, WHETHER EXPRESS OR IMPLIED, ARISING
        BY
        LAW,
        CUSTOM,
        PRIOR ORAL OR WRITTEN STATEMENTS, OR OTHERWISE, INCLUDING, WITHOUT LIMITATION, ANY WARRANTY OF
        MERCHANTABILITY, QUALITY,
        LIKELY-RESULT, FITNESS FOR A PARTICULAR PURPOSE OR NON-INFRINGEMENT.
    </span>
</div>
<div class="paragraph">
    <h3>Limitation of Liability</h3>
    <span class="text">
        POINTGRAB WILL NOT BE LIABLE TO YOU IN ANY WAY FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL OR
        CONSEQUENTIAL DAMAGE, COSTS, EXPENSES AND PAYMENTS OR FOR ANY LOSS OF PROFIT OR LOSS OF DATA, EITHER
        IN
        TORT
        (INCLUDING WITHOUT LIMITATION NEGLIGENCE), CONTRACT (INCLUDING PRE-CONTRACTUAL STATEMENTS), STRICT
        LIABILITY, STATUTORY LIABILITY OR IN ANY OTHER CAUSE OF ACTION, ARISING FROM, OR IN CONNECTION WITH
        THESE
        TERMS OR THE SERVICE, WHETHER OR NOT POINTGRAB OR ANY SUCH AFFILIATE HAS BEEN ADVISED OF THE
        POSSIBILITY
        OF
        SUCH DAMAGE, LOSS, COSTS, EXPENSES OR PAYMENTS.
    </span>
</div>
<div class="paragraph">
    <h3>Governing Law and Jurisdiction</h3>
    <span class="text">
        These Terms will be governed solely by the laws of the State of New York, New York, without giving
        effect to
        any conflicts of law principles, which would result in the application of the laws of a jurisdiction
        other
        than the State of New York, and excluding the United Nations Convention on Contracts for the
        International
        Sale of Goods (CISG). All disputes, controversies, differences, or claims arising out of or relating
        to
        this
        Agreement (including the validity, breach or termination hereof) or our use of the Service,
        regardless
        of
        the date of accrual and including past, pending, and future claims, shall be binding arbitration
        administered by JAMS pursuant to its Streamlined Arbitration Rules & Procedures. Upon either party’s
        request, the arbitrator will issue an order requiring that confidential information of either party
        disclosed during the arbitration (whether in documents or orally) may not be used or disclosed
        except in
        connection with the arbitration or a proceeding to enforce the arbitration award and that any
        permitted
        filing of confidential information must be done under seal. The seat of any arbitration shall be New
        York
        State. EACH PARTY WAIVES ITS RIGHT TO GO TO COURT, TO A TRIAL BY JURY, AND TO PARTICIPATE IN A CLASS
        ACTION,
        CLASS ARBITRATION, OR OTHER REPRESENTATIVE PROCEEDING WITH RESPECT TO ANY CLAIM SUBJECT TO
        ARBITRATION.
        Notwithstanding the foregoing, either party may seek an interim injunction or other interim
        equitable
        relief
        in any court of competent jurisdiction.
    </span>
</div>
<div class="paragraph">
    <h3>General</h3>
    <span class="text">
        These Terms along with the Privacy Policy specified at <a target="_blank"
            href="https://www.pointgrab.com/privacy-policy">
            https://www.pointgrab.com/privacy-policy</a> constitute the entire agreement between you and
        PointGrab with respect to the access and use of the Service and supersede any and all agreements,
        negotiations and
        understandings. You may not assign your rights and obligations under these Terms without PointGrab’s
        prior
        written
        consent and any assignment without such prior written consent will be void. PointGrab may freely
        assign
        its
        rights and
        obligations herein. Subject to any provisions herein with regard to assignment, all covenants and
        agreements
        herein
        shall bind and inure to the benefit of the respective heirs, executors, administrators, successors
        and
        assigns of the
        parties hereto. If any provision of these Terms is held by a court of competent jurisdiction to be
        illegal,
        invalid,
        unenforceable, or otherwise contrary to law, the remaining provisions of these Terms will remain in
        full
        force and
        effect.
    </span>
</div>
<div class="paragraph">
    <h3>Contact Us</h3>
    <span class="text">
        You may contact PointGrab with any question about the Service, through the contact form at: <a target="_blank"
            href="https://www.pointgrab.com/privacy-policy">
            https://www.pointgrab.com/privacy-policy</a>. PointGrab
        will make efforts to address your inquiry promptly.
    </span>
</div>