<div class="app-border">
    <div class="row top-line"></div>
    <div class="row info">
        <div class="cell">
            <div class="name">{{res.noTelemetryLicense}}</div>
            <div class="description">{{res.noTelemetryLicenseDescription}}</div>
        </div>
        <div class="cell">
            <div class="panel-buttons">
                <div class="wrapper-ripple buy-button"
                    nk-ripple>
                    <a [href]="'mailto:info@pointgrab.com'"
                        class="button primary-button">{{res.buyLicense}}</a>
                </div>
            </div>
        </div>
    </div>
</div>