import {
    Component
} from '@angular/core';

@Component({
    selector: 'terms-of-use-page',
    templateUrl: 'termsOfUsePage.component.html',
    styleUrls: ['./termsOfUsePage.component.scss'],
    host: {
        class: 'terms-of-use-page'
    }
})

export class TermsOfUsePageComponent {

    constructor(
    ) {
    }

}
