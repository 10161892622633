<div class="ngdialog-content"
    [formGroup]="form">
    <div class="popup-header">
        <div class="title"></div>
        <fa-icon [icon]="{prefix: 'fac',  iconName: 'cancel-light'}"
            (click)="close()"
            class="action-block fa-icon"></fa-icon>
    </div>
    <div class="popup-content form-block"
        [@fadeInContentByOpacityForHidden]="!res.isInit ? 'hidden':'visible'">
        <div class="flex-row">
            <div class="flex-columns clock-icon-wrapper">
                <div class="clock-icon"></div>
            </div>
        </div>
        <div class="flex-row">
            <div class="flex-columns free-trial-ends">
                <span class="label">Free trial has ended.</span><span class="value"></span>
            </div>
        </div>
        <div class="flex-row">
            <div>
                <div style="text-align: center;">Thank you for using PointGrab Analytics.</div>
                If the platform is not included in your license fee, and you wish to keep using
                our analytics platform, kindly contact info&#64;pointgrab.com
            </div>
        </div>
        <div class="flex-row">
            <div class="flex-columns">
                {{res.toContinuePleaseContactOurSales}}
            </div>
        </div>
        <div class="flex-row">
            <div class="flex-columns">
                <div class="panel-buttons">
                    <div class="wrapper-ripple"
                        nk-ripple>
                        <a [href]="'mailto:info@pointgrab.com'"
                            (click)="upgradeNow()"
                            class="button primary-button">{{res.upgradeNow}}</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex-row">
            <div class="flex-columns checkbox">
                <mat-checkbox formControlName="isShow"></mat-checkbox>
                <span class="label">{{res.dontShowThisAgain}}</span>
            </div>
        </div>
    </div>

</div>