import {
    Component,
    ElementRef,
    OnDestroy,
    OnInit
} from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationProvider } from '@app/core';
import {
    EulaModel,
    LicenseRes
} from '@app/core';
import {
    ErrorHandlerService,
    PopupsDialogService,
    SubscriptionNotifier
} from '@app/shared';

@Component({
    selector: 'eula',
    templateUrl: 'eula.component.html',
    styleUrls: ['./eula.component.scss'],
    providers: [
        EulaModel
    ],
    host: {
        class: 'eula'
    }
})

export class EulaComponent implements OnDestroy, OnInit {

    private subscriptions: SubscriptionNotifier = new SubscriptionNotifier();

    constructor(
        private router: Router,
        private errorHandlerService: ErrorHandlerService,
        private popupsDialogService: PopupsDialogService,
        private elementRef: ElementRef,
        private authenticationProvider: AuthenticationProvider,
        public model: EulaModel,
        public res: LicenseRes
    ) {
    }

    public ngOnDestroy(): void {
        this.subscriptions.destroy();
    }

    public ngOnInit(): void {
        this.subscriptions.destroy();
    }

    public approve(): void {
        this.popupsDialogService.startRelativeLoading(this.elementRef.nativeElement);
        this.model
            .approve()
            .pipe(this.subscriptions.register())
            .subscribe({
                next: () => {
                    this.authenticationProvider.currentUserValue.licenseOptions.setEndUserLicenseAgreement();
                    this.router.navigate([this.authenticationProvider.getDefaultRoutApp()]);
                    this.popupsDialogService.breackRelativeLoading(this.elementRef.nativeElement);
                },
                error: (error) => {
                    this.popupsDialogService.breackRelativeLoading(this.elementRef.nativeElement);
                    this.errorHandlerService.handleError(error);
                }
            });
    }

    public isDisabledApprove(): boolean {
        return !this.model.isApproved;
    }

}
