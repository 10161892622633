import {
    OnInit,
    Component,
    OnDestroy
} from '@angular/core';
import {
    AuthenticationProvider,
    GlobalRes,
    LicenseRes,
    UserSettingsModel
} from '@app/core';
import {
    MatDialogRef
} from '@angular/material/dialog';
import {
    FormBuilder,
    FormGroup
} from '@angular/forms';
import {
    FADE_IN_CONTENT_BY_OPACITY_FOR_HIDDEN,
    SubscriptionNotifier
} from '@app/shared';

@Component({
    selector: 'free-trial-ends-modal',
    templateUrl: 'freeTrialEndsModal.component.html',
    styleUrls: ['./freeTrialEndsModal.component.scss'],
    providers: [
    ],
    animations: [
        FADE_IN_CONTENT_BY_OPACITY_FOR_HIDDEN],
    host: {
        class: 'free-trial-ends-modal'
    }
})

export class FreeTrialEndsModalComponent implements OnInit, OnDestroy {

    private subscriptions: SubscriptionNotifier = new SubscriptionNotifier();

    public form: FormGroup;

    // public get freeTrialEnds(): string {
    //     return `${this.authenticationProvider.currentUserValue.licenseOptions.freeTrialEndsInDays} ${this.res.days}`;
    // }

    private pFreeTrialEnds: number;
    public get freeTrialEnds(): string {
        return `${this.pFreeTrialEnds} ${this.res.days}`;
    }

    constructor(
        private formBuilder: FormBuilder,
        private userSettingsModel: UserSettingsModel,
        private authenticationProvider: AuthenticationProvider,
        private dialogRef: MatDialogRef<FreeTrialEndsModalComponent>,
        public globalRes: GlobalRes,
        public res: LicenseRes
    ) {

        this.form = this.formBuilder.group({
            isShow: ['']
        });
    }

    public ngOnDestroy(): void {
        this.subscriptions.destroy();
    }

    public ngOnInit(): void {
        this.pFreeTrialEnds = Math.max(Math.ceil((new Date('10.01.2022').valueOf() - new Date().valueOf())/ (1000 * 3600 * 24)), 0);
        this.form
            .controls
            .isShow
            .valueChanges
            .pipe(this.subscriptions.register())
            .subscribe(value => {
                if (value) {
                    this.userSettingsModel.localSettings.data.licenseSettings.hideFreeTrialWarning = true;
                    this.userSettingsModel.localSettings.save();
                }
            })
    }

    public close(): void {
        this.dialogRef.close();
    }

    public upgradeNow(): void {

    }


}
