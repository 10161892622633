import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import { LicenseRoutingModule } from './licenseRouting.module';
import { LicenseStorageRemoteProvider } from '@app/storage';
import {
  FreeTrialEndsModalComponent,
  EulaComponent,
  NoTelemetryComponent,
  NoAnalyticsComponent,
  TermsOfUseContentComponent,
  TermsOfUsePageComponent
} from './components';
import { PerfectScrollbarModule } from 'ngx-om-perfect-scrollbar';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonControlsModule } from '@app/shared/controls/commonControls.module';

const COMPONENTS = [
  EulaComponent,
  FreeTrialEndsModalComponent,
  NoTelemetryComponent,
  NoAnalyticsComponent,
  TermsOfUseContentComponent,
  TermsOfUsePageComponent
];

@NgModule({
  declarations: COMPONENTS,
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    PerfectScrollbarModule,
    CommonControlsModule,
    LicenseRoutingModule
  ],
  exports: [
   NoAnalyticsComponent,
   NoTelemetryComponent
  ],
  providers: [
    LicenseStorageRemoteProvider
  ]
})
export class LicenseModule { }
