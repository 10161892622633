import { NgModule } from '@angular/core';
import {
  RouterModule,
  Routes
} from '@angular/router';
import { EulaComponent } from './components/eula';
import { AuthGuard } from '../auth/guards';
import { TermsOfUsePageComponent } from './components';

const routes: Routes = [
  {
    path: 'eula',
    canActivate: [AuthGuard],
    component: EulaComponent,
    data: { animation: 'EulaPage' }
  },
  {
    path: 'terms-of-use-page',
    component: TermsOfUsePageComponent,
    data: { animation: 'TermsOfUsePage' }
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class LicenseRoutingModule { }
